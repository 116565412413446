import React from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {Text} from 'wix-ui-tpa/cssVars';
import {Checkboxes} from '../../../../../Checkboxes/Checkboxes';
import {classes} from './PlaceOrder.st.css';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {ViolationsAndPlaceOrderButton} from '../../../../../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../../../../../types/checkoutApp.types';
import {TextPriority} from 'wix-ui-tpa';
import {useControllerProps} from '../../../../../Widget/ControllerContext';

import {SPECS} from '../../../../../../../common/constants';

export enum PlaceOrderSectionDataHook {
  root = 'PlaceOrderSectionDataHook.root',
  title = 'PlaceOrderSectionDataHook.title',
  subtitle = 'PlaceOrderSectionDataHook.subtitle',
  violations = 'PlaceOrderSectionDataHook.violations',
}

export const PlaceOrder = ({checkboxesRef}: {checkboxesRef?: React.RefObject<HTMLDivElement>}) => {
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {isDonationsTheme},
    checkoutSettingsStore: {checkoutContent},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const shouldOverrideContent = experiments.enabled(SPECS.UseCheckoutContentOverride);
  const reviewOrderText = isDonationsTheme
    ? localeKeys.checkout.donations.review_donation.title()
    : localeKeys.checkout.place_order.$value();
  const reviewOrderTextWithOverride = shouldOverrideContent
    ? checkoutContent?.reviewAndPlaceOrder ?? reviewOrderText
    : reviewOrderText;

  return (
    <div className={classes.root} data-hook={PlaceOrderSectionDataHook.root}>
      <div className={classes.title}>
        <Text
          data-hook={PlaceOrderSectionDataHook.title}
          tagName={'h2'}
          priority={TextPriority.primary}
          className={classes.titleText}>
          {reviewOrderTextWithOverride}
        </Text>
      </div>
      <div className={classes.subtitle}>
        <Text data-hook={PlaceOrderSectionDataHook.subtitle}>
          {isDonationsTheme
            ? localeKeys.checkout.donations.review_donation.description()
            : localeKeys.checkout.place_order.description()}
        </Text>
      </div>
      <Checkboxes checkboxesRef={checkboxesRef} />
      <ViolationsAndPlaceOrderButton
        location={ViolationsAndPlaceOrderButtonLocation.paymentAndPlaceOrderStep}
        checkboxesRef={checkboxesRef}
      />
    </div>
  );
};
