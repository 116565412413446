import {CheckoutSettingsModel} from '../../../../domain/models/checkout/checkoutSettings/CheckoutSettings.model';
import {
  AddressTarget,
  FieldPropertiesByTarget,
  FormFieldTarget,
  BillingFormOverrides,
} from '../../../../types/checkoutApp.types';
import {
  getExcludeGoogleAutoCompleteOverrides,
  getSettingsOverrides,
  mergeOverrides,
  getLabelsOverrides,
  mandatoryOverride,
  getAddressFormInitialState,
  getContactFormInitialState,
} from '../form.utils';
import {FormOverride} from '@wix/form-viewer/dist/types/services/form-overrides';
import {ILocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {ContactModel} from '../../../../domain/models/checkout/Contact.model';
import {DEFAULT_COUNTRY} from '../../constants';
import {AddressModel} from '../../../../domain/models/checkout/Address.model';
import {FormValues, VatIDValue, CountryCode} from '@wix/form-fields';
import {ValueType} from '@wix/form-fields/dist/types/ui/form/types';
import {isCountryWithVat} from '../../../../domain/utils/isCountryWithVat';
import {VatIdFragment, WixCommonVatType} from '../../../../gql/graphql';
import {getCommonVatId} from '../../../../domain/utils/vat.util';
import {CashierMandatoryField, CashierMandatoryFieldsOverrides} from '../../../../types/payment.types';
import {Experiments} from '@wix/yoshi-flow-editor';

const cashierFieldToAddressTarget = {
  [CashierMandatoryField.address]: AddressTarget.address_line,
  [CashierMandatoryField.zipCode]: AddressTarget.postal_code,
  [CashierMandatoryField.countryCode]: AddressTarget.country,
  [CashierMandatoryField.houseNumber]: AddressTarget.street_number,
  [CashierMandatoryField.street]: AddressTarget.street_name,
  [CashierMandatoryField.state]: AddressTarget.subdivision,
  [CashierMandatoryField.city]: AddressTarget.city,
};

function mapCashierMandatoryFieldToOverride(
  cashierMandatoryField: CashierMandatoryField,
  mandatory?: boolean
): BillingFormOverrides | undefined {
  /* istanbul ignore next */
  if (!mandatory) {
    return {};
  }
  switch (cashierMandatoryField) {
    case CashierMandatoryField.address:
    case CashierMandatoryField.city:
    case CashierMandatoryField.countryCode:
    case CashierMandatoryField.houseNumber:
    case CashierMandatoryField.state:
    case CashierMandatoryField.street:
    case CashierMandatoryField.zipCode: {
      const target = cashierFieldToAddressTarget[cashierMandatoryField];
      return {
        address: {
          [target]: mandatoryOverride,
        },
      };
    }
    case CashierMandatoryField.phone:
      return {
        phone: mandatoryOverride,
      };
    /* istanbul ignore next */
    default:
      /* istanbul ignore next */
      return undefined;
  }
}

export function getCashierOverrides(cashierOverrides: CashierMandatoryFieldsOverrides): BillingFormOverrides {
  const fieldTargets = Object.keys(cashierOverrides) as (keyof typeof cashierOverrides)[];

  return fieldTargets.reduce((accOverrides, fieldTarget) => {
    const fieldOverride = mapCashierMandatoryFieldToOverride(fieldTarget, cashierOverrides[fieldTarget]);
    /* istanbul ignore next */
    if (!fieldOverride) {
      return accOverrides;
    }
    return mergeOverrides(accOverrides, fieldOverride);
  }, {});
}

function mapFormFieldPropsToOverrides(props: FieldPropertiesByTarget[string]): FormOverride {
  if (!props) {
    return {
      hidden: true,
      required: false,
    };
  }
  /* istanbul ignore else */
  if (typeof props?.hidden === 'boolean' && typeof props?.required === 'boolean') {
    return {
      hidden: props.hidden,
      required: props.required,
    };
  }
  /* istanbul ignore next */
  return {};
}

function getVatFormInitialState(contact?: ContactModel, nullIfMIssing?: boolean) {
  if (nullIfMIssing && !contact?.vatId?.id) {
    return {vat_id: null};
  }

  return {
    vat_id: {
      id: contact?.vatId?.id ?? /* istanbul ignore next */ '',
      type: contact?.vatId?.type ?? /* istanbul ignore next */ WixCommonVatType.UNSPECIFIED,
    },
  };
}

export function getShippingDetailsRelatedFieldsOverrides({
  formFieldsProperties,
}: {
  formFieldsProperties: FieldPropertiesByTarget;
}): BillingFormOverrides {
  const phoneProps = formFieldsProperties[FormFieldTarget.phone];
  const companyProps = formFieldsProperties[FormFieldTarget.company_name];
  const addressProps = formFieldsProperties[FormFieldTarget.address] as FieldPropertiesByTarget | undefined;
  const addressLine2Props = addressProps ? addressProps[AddressTarget.address_line_2] : undefined;

  return {
    phone: mapFormFieldPropsToOverrides(phoneProps),
    company_name: mapFormFieldPropsToOverrides(companyProps),
    address: {
      address_line_2: mapFormFieldPropsToOverrides(addressLine2Props),
    },
  };
}

export function getVatIdOverride(country: CountryCode, experiments?: Experiments): BillingFormOverrides {
  return isCountryWithVat(country, experiments)
    ? {
        vat_id: {
          hidden: false,
          required: true,
        },
      }
    : {};
}

export function getVatFromVatFormValues(vatFormValues: FormValues | null): VatIdFragment | undefined {
  return vatFormValues ? getCommonVatId(vatFormValues.vat_id as VatIDValue) : /* istanbul ignore next */ undefined;
}

export type BillingFormRelevantSettings = Pick<
  CheckoutSettingsModel,
  'enabledDeliveryCountries' | 'isLocalDeliveryByZipCodeEnabled'
>;

export function getBillingFormOverrides({
  cashierMandatoryFields,
  checkoutSettings,
  shippingDetailsFieldsProps,
  excludeGoogleAutoComplete,
  localeKeys,
  country,
  experiments,
}: {
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  checkoutSettings: BillingFormRelevantSettings;
  shippingDetailsFieldsProps: FieldPropertiesByTarget;
  excludeGoogleAutoComplete?: boolean;
  localeKeys?: ILocaleKeys;
  country?: CountryCode | null;
  experiments?: Experiments;
}): BillingFormOverrides {
  const cashierMandatoryFieldsOverrides = getCashierOverrides(cashierMandatoryFields);
  const settingsOverrides = getSettingsOverrides({checkoutSettings});
  const shippingDetailsRelatedFieldsOverrides = getShippingDetailsRelatedFieldsOverrides({
    formFieldsProperties: shippingDetailsFieldsProps,
  });
  const hideCountryOverride = {};
  const excludeGoogleAutoCompleteOverrides = getExcludeGoogleAutoCompleteOverrides({excludeGoogleAutoComplete});
  const labelsOverrides = localeKeys ? getLabelsOverrides({localeKeys}) : /* istanbul ignore next */ {};
  const vatIdOverride = country ? getVatIdOverride(country, experiments) : {};

  return mergeOverrides(
    settingsOverrides,
    shippingDetailsRelatedFieldsOverrides,
    hideCountryOverride,
    excludeGoogleAutoCompleteOverrides,
    labelsOverrides,
    vatIdOverride,
    cashierMandatoryFieldsOverrides
  );
}

export function getBillingFormInitialState({
  contact,
  initialAddress,
  billingContact,
  useUndefinedAsDefaultBillingAddressInCheckout,
}: {
  contact?: ContactModel;
  initialAddress?: AddressModel;
  useUndefinedAsDefaultBillingAddressInCheckout: boolean;

  billingContact?: ContactModel;
}) {
  const country =
    initialAddress?.country ?? (useUndefinedAsDefaultBillingAddressInCheckout ? undefined : DEFAULT_COUNTRY);
  return {
    ...getContactFormInitialState({
      contact,
      country,
    }),
    ...getAddressFormInitialState(initialAddress, country),
    ...getVatFormInitialState(billingContact, true),
  };
}

export function getCountryCode(formValues: FormValues): CountryCode | undefined {
  const address = formValues[FormFieldTarget.address] as {[target: string]: ValueType} | undefined;
  const countryCode = address ? address[AddressTarget.country] : undefined;
  return countryCode ? (countryCode as CountryCode) : undefined;
}
